import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carousels = document.querySelectorAll('.headerCarousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      // const slides = carousel.querySelectorAll('.swiper-slide');
      const args = {
        draggable: true,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        },
        loop: true,
        Mousewheel: true,
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          clickable: true,
        },
        a11y: {
          paginationBulletMessage: '',
          nextSlideMessage: _x('Next', 'Accessibility swiper message'),
          prevSlideMessage: _x('Previous', 'Accessibility swiper message'),
        },
      };

      new Swiper(carousel, args);
    });
  }
});
